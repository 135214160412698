import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import bikeMesh from '../media/four.png'
import bike from '../media/bike.jpeg'
import spkrSetup from '../media/old_rich_speaker_setup.png'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'
import Team from '../media/bikes_team.jpg'

import React, { useState } from 'react';

function Bikes() {

  return (
    <div className='responsiveDiv'>

    <h1 className='mt-[1rem] font-bold'>BIKES</h1>
    <img src={Team} className='w-full m-auto'></img>
   {/* <img src={bikeMesh} className='w-full m-a uto'></img>*/}
    <div className='text-left'>
    <p className='text-left mt-[1rem]'>Building on the foundations of the MËSH and SPRAWL, BIKES aims to use network audio principles to build a mobile collaborative instrumenty.     With BIKES we turn a fleet of four cargo bikes into an interconnected moving instrument for experimental electronic music. Equipped with strong PA loudspeakers and a computer, this concept interacts with its environment, enriching, contrasting and challenging the urban soundscape and acoustics.  
    </p>

    <img src={bike} className='w-full m-auto'></img>
    <p className='text-center italic mt-[1rem]'>The bicycles that we will be using.</p>
    
    <figure class="video">
    <iframe src="https://www.youtube.com/embed/aWo1AhJwv1Q?si=fDoLy8MSggX038fz" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen" ></iframe>
</figure>
<p className='text-center italic mt-[1rem]'>Riding the bikes home from the shop.</p>
    

   
     

      
   
    <p>The bikes will be available to rent for free  for the public to enjoy and experiment with.</p>
    <p className=''>The project is being built in Spring of 2025 and is being done in collaboration with Edison, a local Atlanta electric bike shop. </p>
    

  

   
   
   
  </div>

  </div>
  );
}

export default Bikes;
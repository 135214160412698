import logo from './logo.svg';
import l42iLogo from './media/l42i_white.png'
import gtLogo from './media/logo.png'
import './App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from './media/2024_spring_team_crop.jpg'
import homeCarousel2 from './media/cube4.png'
import homeCarousel3 from './media/rich_speaker_setup.png'
import homeCarousel4 from './media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from './media/Henrik_von_Coler.jpg'
import Home from './components/Home'

import React, { useState, useEffect } from 'react';
import ThreeDBox from './components/3DBox';
import ARCube from './components/ARCube';
import People from './components/People';
import Scz from './components/Scz';
import Mesh from './components/Mesh';
import Contest from './components/Contest';
import Immersend from './components/Immersend';
import Bikes from './components/Bikes';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

function App() {

  const [currentPage, setCurrentPage] = useState('Home');

  const [menu, setMenu] = useState({});

  useEffect(() => {
    // Replace 'path/to/your/menu.txt' with the path to your text file
    fetch('/markdown/NavigationMenu/NavigationMenu.txt')
      .then(response => response.text())
     .then(text => {
       console.log(text)
        const parsedMenu = parseMenu(text);
        setMenu(parsedMenu);
      })
      .catch(error => console.error('Error fetching menu:', error));
  }, []);
  const parseMenu = (input) => {
    const lines = input.split('\n');
    const result = {};
    let currentCategory = null;


    console.log(input)

    lines.forEach(line => {
      // Determine the level of indentation
      const indentLevel = line.search(/\S/);
      
      // Trim the line to remove leading/trailing whitespace
      const trimmedLine = line.trim();
      
      if (indentLevel === 0) {
        // This is a top-level item
        if (trimmedLine) {
          currentCategory = trimmedLine;
          result[currentCategory] = [];
        }
      } else {
        // This is a sub-item
        if (currentCategory && trimmedLine) {
          result[currentCategory].push(trimmedLine);
        }
      }
    });

  

    for(var i =0; i < result.length; i++)
    {
      console.log(result[i])
    }
    return result;
  };



  return (
   <div className='App'>

      

      <Navbar expand="xl" className="customBg sticky-top">
      <Container>

        <Navbar.Brand >
          <a href="/">
        <button><img
              alt=""
              
              src={gtLogo}
              width="200"
              height="200"
              className="d-inline-block align-top"
            />{' '}
            </button>
            </a>
           
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Projects" id="basic-nav-dropdown">
              <NavDropdown.Item href="/projects/3dbox">3DBox</NavDropdown.Item>
              <NavDropdown.Item href="/projects/arcube">
                ARCube
              </NavDropdown.Item>
              <NavDropdown.Item href='/projects/schizosymphony'>
              Schizosymphony
              </NavDropdown.Item>
              <NavDropdown.Item href="/projects/mesh">
              MËSH
              </NavDropdown.Item>
              <NavDropdown.Item href="/projects/immersend">
              ImmersEND
              </NavDropdown.Item>
              <NavDropdown.Item href="/projects/bikes">
              BIKES
              </NavDropdown.Item>
            
            
              
            </NavDropdown>
            <Nav.Link href="/people">People</Nav.Link>
            <Nav.Link href="/3dcontest">3D Contest</Nav.Link>
            
        
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>



    <Router>
     
     
     <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/projects/3dbox" element={<ThreeDBox />} />
       <Route path="/projects/arcube" element={<ARCube />} />
       <Route path="/projects/schizosymphony" element={<Scz />} />
       <Route path="/projects/mesh" element={<Mesh />} />
       <Route path="/projects/immersend" element={<Immersend />} />
       <Route path="/projects/bikes" element={<Bikes />} />
       <Route path="/3dcontest" element={<Contest />} />

       <Route path="/people" element={<People />} />
       
       
     </Routes>
    </Router>

      <div className="myFooter">
    
        <p>

       {/* <a href="mailto:l42i@music.gatech.edu" className='italic'>l42i@music.gatech.edu</a>*/}
 204 Couch Bldg (Music)<br></br>840 McMillan St NW<br></br>Atlanta, GA 30332</p>
      </div>
   
      
    </div>
   
  );
}

export default App;

import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import homeCarousel3 from '../media/rich_speaker_setup.png'
import homeCarousel4 from '../media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'
import Evan from '../media/evan_murray.jpg'
import Orlando from '../media/Orlando_Kenny.jpeg'
import Xinni from '../media/XinniLi.jpeg'
import Luhee from '../media/luhee.png'
import VoltEra from '../media/3dcontest/VoltEra_Final.mp3'
import AgainstTheGrain from '../media/3dcontest/AgainstTheGrain_Final.mp3'
import Unnamed from '../media/3dcontest/Unnamed_Final.mp3'
import contest1 from '../media/3dcontest/contest1.jpg'
import contest2 from '../media/3dcontest/contest2.jpg'
import contest3 from '../media/3dcontest/contest3.jpg'
import contest4 from '../media/3dcontest/contest4.jpg'







import React, { useState } from 'react';

//test

function Contest() {

  return (
    <div className='responsiveDiv'>
    

<h1 className='mt-[1rem] font-bold'>GT3D Contest</h1>
<h3>2024-2025</h3>
<img src={contest4} className='w-[full] m-auto'></img>
<p className='text-center italic mt-[1rem]'>All students who participated in the GT3D Contest.</p>

<div className='text-left'>

<p className='text-left '>
With the first GaTech 3D Composition Contest - or short 3D Contest - the School of Music invited students to compose for our <a href="/projects/3dbox">3DBox</a>. Organized by the Lab for Interaction and Immersion, the call was open to all students at Georgia Tech and also open to any genre - from popular music to sound art and audio plays. 



<br></br><br></br>The only requirement: the resulting pieces should be compatible with our system, using the Ambisonics format.
 
Students worked from Fall 2024 to January 2025 with free access to our spatial sound system. The three finalists received prizes to continue their work in spatial audio: equipment for Ambisonics and binaural field recorders. 
<br></br>
<br></br>


 
We wanted to create a creative culture around spatial system. We are proud to present the winners of our first contest:
<br></br>
<br></br>
<img src={contest3} className='w-[full] m-auto'></img>
<p className='text-center italic mt-[1rem]'>GT3D Contest Winners<br></br> Ishaan Jagyasi, Peyman Salimi, and Jacob Westerstahl</p>



<p className='text-center text-xl font-bold mt-[0.5rem]'>Winning Submissions:</p>

 <ol>

 <li className>
  <span className='font-bold text-xl'>1. Peyman Salimi: </span><span className='italic text-xl'>Volt Era</span>
  <br></br>
  <br></br>
  <audio controls>

  <source src={VoltEra} type="audio/mpeg"/>
  Your browser does not support the audio element.
  </audio>
    <br></br>
  <p className='font-bold'>About the Composition</p>
  <p>
  "Volt Era" is a five-minute composition created and arranged in 5th-order Ambisonics for immersive 3D spatial audio. The piece was composed for the 3D Composition Contest and mixed in Georgia Tech’s 3D Audio Box at the Rich Building, with a separate binaural mix also created for headphone listening.
The title, "Volt Era," is a play on the name “Volterra,” referencing the ancient Roman thermal pool in Tuscany where the water sounds were recorded. 
<br></br>
<br></br>
“Volt” evokes energy and fire, creating a thematic tension between the opposing elements of water and fire that defines the piece. Inspired by Xenakis’ Concret PH, Volt Era juxtaposes organic field recordings with synthetic textures, weaving an intricate soundscape of contrasts—chaos and calm, tension and release, synthetic and acoustic.

  </p>

  <p className='font-bold'>About the Composer</p>
  <p>
  Peyman Salimi is a composer, music producer, and vocalist whose research examines how lyrics shape emotional responses to music, blending academic inquiry with his experience as a multi-lingual artist. Recently, he has also shown a growing interest in immersive audio, exploring the creative possibilities of spatial sound design. He has released three solo albums, two albums with his Florence-based band, The Allophones, and composed works like Doublethink, an electroacoustic piece for piano, fixed media, and live electronics, as well as the soundtrack for Audenie, a short film nominated at international festivals. Peyman’s work bridges creative and academic perspectives, exploring new ways to connect artists and listeners.
  </p>
</li>
<br></br>
<li className>
  <span className='font-bold text-xl'>2. Ishaan Jagyasi: </span><span className='italic text-xl'>Against the Grain</span>
  <br></br>
  <br></br>
  <audio controls>

  <source src={AgainstTheGrain} type="audio/mpeg"/>
  Your browser does not support the audio element.
  </audio>

  <br></br>
  <p className='font-bold'>About the Composition</p>
  <p>
  
'Against the Grain' is an experimental techno track inspired by the biological processes that activate when a host faces danger from an external entity. Complex beings like us have survival mechanisms that start functioning without even requiring conscience and these reactions are hard coded inside the saviour cells, which is honestly fascinating to me.
<br></br>
<br></br>
Survival is essential for the sustainability of life, and that has been the case since the day life came into effect on this weird planet. Billions and trillions of probabilities had to result in this one scenario where life could actually develop on earth and it still seems a big unknown as to why exactly it went that way.
<br></br>
<br></br>
The sense of survival exists not only within us living creatures, but also on a cosmic level. At the risk of sounding ill-knowledged, the very fact that earth has its magnetosphere in just the right way so that radiations from the sun and the space are deflected, is truly appalling to me. This is one of the many phenomenons that I can point at to show how survival works in a world beyond our nature as well.
<br></br>
<br></br>
'Against the Grain' is inspired by these instincts of survival that exist within our bodies and around us.

  </p>

  <p className='font-bold'>About the Composer</p>
  <p>
  ‘knordest’ is the artistic identity of Ishaan Jagyasi, music producer from Mumbai, India. ‘knordest’ was born out of Ishaan’s curiosity and passion for exploring music at the intersection of technology, human psychology and modern art. His music carries an emotional resonance along with intricate sound design that provides an immersive listening environment and delivers an other-worldly aural experience. Even though most of his musical work is inspired by genres like Electronica and Experimental, his music is a blend of genres such as Hindustani Classical, Orchestral, Ambient, IDM, Melodic Techno and more.
  </p>

  
</li>
<br></br>
<li><span className='font-bold text-xl'>3: Jacob Westerstahl: </span><span className='italic text-xl'>Unnamed</span>
<br></br>
  <br></br>
<audio controls>

  <source src={Unnamed} type="audio/mpeg"/>
  Your browser does not support the audio element.
  </audio>

  <br></br>
  <p className='font-bold'>About the Composition</p>
  <p>
 
The piece begins with an analog synth as it whooshes around the listener, before moving onto a spliced interview with a “catatonically” schizophrenic man from the 1960s, raising questions about what it means to be a musician and the pursuit of meaningful art. The song also samples parts of Sweet Trip’s “Fruitcake and Cookies” during its bridge before returning to a chorus that soars past the listener’s head.
  </p>

  <p className='font-bold'>About the Composer</p>
  <p>
  Jacob Westerstahl is a first-year undergraduate student at the School of Music passionate about researching how sound can be used to make the world more accessible to those with vision and hearing disabilities. He is also interested in the relatively little-understood role that noise pollution and our audial environment play in our mental and physical well-being and looks forward to tackling some of these issues in his involvement at the GTSoM. As a musician, Jacob plays electric guitar, sings, and has recently begun creating house, ambient, and other electronic music. He draws inspiration from early post-punk visionaries such as Wire, Gang of Four, and Minutemen in the rock bands he plays in, and is influenced by the likes of Aphex Twin, BICEP, and Eric Prydz in his forays into electronic music.
 </p>

</li>

<br></br>
</ol>
{/*
<p className='text-center mt-[1rem]'><span className='font-bold'>Judges</span>
  <br></br>

  <a href="https://music.gatech.edu/people/alexandria-smith" target="_blank">Alexandria Smith</a> <br></br>
<a href="https://music.gatech.edu/brittney-boykin" target="_blank">Brittney Boykin</a><br></br>
<a href="https://music.gatech.edu/people/chaowen-ting" target="_blank">Chaowen Ting</a> <br></br>
<a href="https://music.gatech.edu/people/jeremy-muller" target="_blank">Jeremy Muller </a><br></br>
<a href="https://music.gatech.edu/people/henrik-von-coler" target="_blank">Henrik von Coler</a><br></br>
</p>*/}


<p className='text-center mt-[1rem]'><span className='font-bold'>Judges</span>
  <br></br>

  <a href="https://music.gatech.edu/people/alexandria-smith" target="_blank">Alexandria Smith</a> <br></br>
<a href="https://music.gatech.edu/brittney-boykin" target="_blank">Brittney Boykin</a><br></br>
<a href="https://music.gatech.edu/people/chaowen-ting" target="_blank">Chaowen Ting</a> <br></br>
<a href="https://music.gatech.edu/people/jeremy-muller" target="_blank">Jeremy Muller </a><br></br>
<a href="https://music.gatech.edu/people/henrik-von-coler" target="_blank">Henrik von Coler</a><br></br>
</p>

{/*
<p className="font-bold italic text-center mt-[1rem]">You must attend a 3DBox introduction session to join the contest:</p>
<div className='btnContainer'>
<Button variant="secondary" href="https://doodle.com/sign-up-sheet/participate/8ff81804-2fa3-4250-bf9d-21d38e246611/select" target='_blank' className='btn'>Sign Up for the Introduction Session</Button>

</div>*/}

<br></br>
<br></br>
<div className='btnContainer'>
<h3>Questions?</h3>
</div>
<div className='btnContainer'>
<p className='questions'>If you can't find the information you were looking for, we'll get you to the right place.</p>
</div>
<div className='btnContainer'>
<Button variant="secondary" href="mailto:hshin336@gatech.edu" target='_blank' className='btn'>Contact Us</Button>

</div>
</p>





        
   

    
</div>

</div>
  );
}

export default Contest;